<template>
  <div class="form">
    <!-- cloud storage form component -->
    <div id="cloud-storage-form" class="form">
      <h2>Editing Existing Cloud Storage Location</h2>
      <div v-if="error" class="err-msg">{{ error }}</div>
      <div v-if="loading">Loading...</div>
      <div v-else class="form-content">
        <div class="form-input">
          <label for="cloud-storage-prefix"> Cloud Storage Prefix: </label>
          <input
            id="cloud-storage-prefix"
            v-model="cloudStorage.prefix"
            name="cloud_storage_prefix"
            type="text"
          />
        </div>
        <div class="form-input">
          <label for="cloud-storage-path"> Cloud Storage Path: </label>
          <input
            id="cloud-storage-path"
            v-model="cloudStorage.path"
            name="cloud_storage_path"
            type="text"
          />
        </div>
        <div class="form-input">
          <label for="cloud-storage-version">
            Cloud Storage Code Version:
          </label>
          <input
            id="cloud-storage-version"
            v-model="cloudStorage.version"
            name="cloud_storage_version"
            type="text"
          />
        </div>
        <button class="save-btn" @click="save">Save</button>
      </div>
    </div>
    <!-- cloud storage form component -->
  </div>
</template>

<script lang="ts">
import apiFetch from "@/utils/request";
import { AxiosResponse } from "axios";
import { Vue } from "vue-class-component";
import { CloudStorage } from "../../types/index";

class EditCloudStorage extends Vue {
  cloudStorage: CloudStorage = {
    prefix: "",
    path: "",
    version: "",
  };
  loading: boolean = true;
  error: string = "";

  created() {
    console.log(" this.$route.params.id: ", this.$route.params.id);
    this.getCloudStorage(Number(this.$route.params.id)).then(() => {
      this.loading = false;
    });
  }

  async getCloudStorage(id: number) {
    const response: AxiosResponse<{ data: CloudStorage }> =
      await apiFetch.cloudStorage.getCloudStorageDetail(id);
    this.cloudStorage = response?.data?.data;
  }

  async save() {
    try {
      await this.editCloudStorage(Number(this.$route.params.id));
      this.$router.push({ path: "/cloud-storage" });
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }

  async editCloudStorage(id: number) {
    const newCloudStorage: CloudStorage = { ...this.cloudStorage };
    await apiFetch.cloudStorage.updateCloudStorageDetail(id, newCloudStorage);
  }
}
export default EditCloudStorage;
</script>
