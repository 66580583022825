
import apiFetch from "@/utils/request";
import { AxiosResponse } from "axios";
import { Vue } from "vue-class-component";
import { CloudStorage } from "../../types/index";

class EditCloudStorage extends Vue {
  cloudStorage: CloudStorage = {
    prefix: "",
    path: "",
    version: "",
  };
  loading: boolean = true;
  error: string = "";

  created() {
    console.log(" this.$route.params.id: ", this.$route.params.id);
    this.getCloudStorage(Number(this.$route.params.id)).then(() => {
      this.loading = false;
    });
  }

  async getCloudStorage(id: number) {
    const response: AxiosResponse<{ data: CloudStorage }> =
      await apiFetch.cloudStorage.getCloudStorageDetail(id);
    this.cloudStorage = response?.data?.data;
  }

  async save() {
    try {
      await this.editCloudStorage(Number(this.$route.params.id));
      this.$router.push({ path: "/cloud-storage" });
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }

  async editCloudStorage(id: number) {
    const newCloudStorage: CloudStorage = { ...this.cloudStorage };
    await apiFetch.cloudStorage.updateCloudStorageDetail(id, newCloudStorage);
  }
}
export default EditCloudStorage;
